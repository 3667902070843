/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';

/**
 * 
 * @export
 * @interface JsonSerie
 */
export interface JsonSerie {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonSerie
     */
    allowedActions?: Array<JsonSerieAllowedActionsEnum>;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonSerie
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {string}
     * @memberof JsonSerie
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonSerie
     */
    createdBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonSerie
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonSerie
     */
    lang?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonSerie
     */
    serieFlags?: Array<JsonSerieSerieFlagsEnum>;
    /**
     * 
     * @type {number}
     * @memberof JsonSerie
     */
    serieId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonSerie
     */
    serieType?: JsonSerieSerieTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonSerie
     */
    status?: JsonSerieStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonSerie
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonSerie
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonSerie
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonSerieAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonSerieSerieFlagsEnum {
    RegEmail = 'REG_EMAIL',
    RegPosition = 'REG_POSITION',
    RegDepartment = 'REG_DEPARTMENT',
    RegName = 'REG_NAME',
    RegBranch = 'REG_BRANCH',
    RegDuration = 'REG_DURATION',
    PollStepper = 'POLL_STEPPER'
}/**
* @export
* @enum {string}
*/
export enum JsonSerieSerieTypeEnum {
    Broadcast = 'BROADCAST',
    Audit = 'AUDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonSerieStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}

export function JsonSerieFromJSON(json: any): JsonSerie {
    return JsonSerieFromJSONTyped(json, false);
}

export function JsonSerieFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonSerie {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'lang': !exists(json, 'lang') ? undefined : json['lang'],
        'serieFlags': !exists(json, 'serieFlags') ? undefined : json['serieFlags'],
        'serieId': !exists(json, 'serieId') ? undefined : json['serieId'],
        'serieType': !exists(json, 'serieType') ? undefined : json['serieType'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonSerieToJSON(value?: JsonSerie | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'description': value.description,
        'lang': value.lang,
        'serieFlags': value.serieFlags,
        'serieId': value.serieId,
        'serieType': value.serieType,
        'status': value.status,
        'title': value.title,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

