/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonCompany,
    JsonCompanyFromJSON,
    JsonCompanyFromJSONTyped,
    JsonCompanyToJSON,
} from './JsonCompany';
import {
    JsonCompanyBranch,
    JsonCompanyBranchFromJSON,
    JsonCompanyBranchFromJSONTyped,
    JsonCompanyBranchToJSON,
} from './JsonCompanyBranch';
import {
    JsonCompanyDepartment,
    JsonCompanyDepartmentFromJSON,
    JsonCompanyDepartmentFromJSONTyped,
    JsonCompanyDepartmentToJSON,
} from './JsonCompanyDepartment';
import {
    JsonCompanyPosition,
    JsonCompanyPositionFromJSON,
    JsonCompanyPositionFromJSONTyped,
    JsonCompanyPositionToJSON,
} from './JsonCompanyPosition';
import {
    JsonEpisode,
    JsonEpisodeFromJSON,
    JsonEpisodeFromJSONTyped,
    JsonEpisodeToJSON,
} from './JsonEpisode';
import {
    JsonSerie,
    JsonSerieFromJSON,
    JsonSerieFromJSONTyped,
    JsonSerieToJSON,
} from './JsonSerie';

/**
 * 
 * @export
 * @interface JsonSerieUserRegistrationContext
 */
export interface JsonSerieUserRegistrationContext {
    /**
     * 
     * @type {JsonCompany}
     * @memberof JsonSerieUserRegistrationContext
     */
    company?: JsonCompany;
    /**
     * 
     * @type {Array<JsonCompanyBranch>}
     * @memberof JsonSerieUserRegistrationContext
     */
    companyBranches?: Array<JsonCompanyBranch>;
    /**
     * 
     * @type {Array<JsonCompanyDepartment>}
     * @memberof JsonSerieUserRegistrationContext
     */
    companyDepartments?: Array<JsonCompanyDepartment>;
    /**
     * 
     * @type {Array<JsonCompanyPosition>}
     * @memberof JsonSerieUserRegistrationContext
     */
    companyPositions?: Array<JsonCompanyPosition>;
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonSerieUserRegistrationContext
     */
    companySerieFlags?: Array<JsonSerieUserRegistrationContextCompanySerieFlagsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonSerieUserRegistrationContext
     */
    dashboardToken?: string;
    /**
     * 
     * @type {Array<JsonEpisode>}
     * @memberof JsonSerieUserRegistrationContext
     */
    episodes?: Array<JsonEpisode>;
    /**
     * 
     * @type {JsonSerie}
     * @memberof JsonSerieUserRegistrationContext
     */
    serie?: JsonSerie;
}

/**
* @export
* @enum {string}
*/
export enum JsonSerieUserRegistrationContextCompanySerieFlagsEnum {
    RegEmail = 'REG_EMAIL',
    RegPosition = 'REG_POSITION',
    RegDepartment = 'REG_DEPARTMENT',
    RegName = 'REG_NAME',
    RegBranch = 'REG_BRANCH',
    RegDuration = 'REG_DURATION',
    PollStepper = 'POLL_STEPPER'
}

export function JsonSerieUserRegistrationContextFromJSON(json: any): JsonSerieUserRegistrationContext {
    return JsonSerieUserRegistrationContextFromJSONTyped(json, false);
}

export function JsonSerieUserRegistrationContextFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonSerieUserRegistrationContext {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'company': !exists(json, 'company') ? undefined : JsonCompanyFromJSON(json['company']),
        'companyBranches': !exists(json, 'companyBranches') ? undefined : ((json['companyBranches'] as Array<any>).map(JsonCompanyBranchFromJSON)),
        'companyDepartments': !exists(json, 'companyDepartments') ? undefined : ((json['companyDepartments'] as Array<any>).map(JsonCompanyDepartmentFromJSON)),
        'companyPositions': !exists(json, 'companyPositions') ? undefined : ((json['companyPositions'] as Array<any>).map(JsonCompanyPositionFromJSON)),
        'companySerieFlags': !exists(json, 'companySerieFlags') ? undefined : json['companySerieFlags'],
        'dashboardToken': !exists(json, 'dashboardToken') ? undefined : json['dashboardToken'],
        'episodes': !exists(json, 'episodes') ? undefined : ((json['episodes'] as Array<any>).map(JsonEpisodeFromJSON)),
        'serie': !exists(json, 'serie') ? undefined : JsonSerieFromJSON(json['serie']),
    };
}

export function JsonSerieUserRegistrationContextToJSON(value?: JsonSerieUserRegistrationContext | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company': JsonCompanyToJSON(value.company),
        'companyBranches': value.companyBranches === undefined ? undefined : ((value.companyBranches as Array<any>).map(JsonCompanyBranchToJSON)),
        'companyDepartments': value.companyDepartments === undefined ? undefined : ((value.companyDepartments as Array<any>).map(JsonCompanyDepartmentToJSON)),
        'companyPositions': value.companyPositions === undefined ? undefined : ((value.companyPositions as Array<any>).map(JsonCompanyPositionToJSON)),
        'companySerieFlags': value.companySerieFlags,
        'dashboardToken': value.dashboardToken,
        'episodes': value.episodes === undefined ? undefined : ((value.episodes as Array<any>).map(JsonEpisodeToJSON)),
        'serie': JsonSerieToJSON(value.serie),
    };
}

