import {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";

//import {useAppTranslation} from '../../services/i18n';
import {useAppDispatch} from '../../store';
import styles from '../../assets/styles/layout';
import {fetchCurrentRelay} from '../../store/auth';
import {JsonSerieSerieTypeEnum} from '../../generated-api';

import MicroSiteMainPage from "../microsite/MicroSiteMainPage";

import atairuLinks from '../../assets/images/lab/atairulab_links.svg';

import * as React from "react";

const AuditPage = () => {
	// const t = useAppTranslation();
	const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  // const [context, setContext] = useState<JsonSerieUserRegistrationContext>();
  const [relayGuid, setRelayGuid] = useState<string>();
  const [lang, setLang] = useState<string>();
  const [single, setSingle] = useState<boolean>(false);
  
  const { clientGuid } = useParams();

  useEffect(() => {
    if (clientGuid) {
      dispatch(fetchCurrentRelay(clientGuid))
        .then((result) => {
          setRelayGuid((result.payload as any).data.relayGuid);
          setLang((result.payload as any).data.lang);
          setSingle((result.payload as any).data.isPollStepper);
        });
    }
  }, [dispatch, clientGuid])

  return (
    relayGuid && clientGuid ?
    <div className={styles.centeredContent} style={{ /*backgroundColor: '#2870ED',*/ /*height: '100vh', display: 'flex', justifyContent: 'center', padding: '20px', boxSizing: 'border-box'*/ }}>
        <img style={{ position: 'fixed', width: '40%', bottom: '-10%', left: '-12%', zIndex: '5' }} alt="Atairu" src={atairuLinks}/>
        <MicroSiteMainPage relayGuid={relayGuid} clientGuid={clientGuid} lang={lang} serieType={JsonSerieSerieTypeEnum.Audit} single={single}/>
    </div> : <div>loading...</div>
	);
}

export default AuditPage;
