import {useAppTranslation} from '../../../services/i18n';
import {useAppDispatch} from '../../../store';
import {useNavigate, useParams} from "react-router-dom";
import {useCallback, useEffect, useState} from 'react';
import {deleteCompanyUser, fetchCompany, fetchCompanySeries, fetchCompanyUsers, saveCompany, saveCompanySerie, saveCompanyUser, sendAdminLink, sendUserLink} from '../../../store/companies';
import CompanyForm from '../../../forms/CompanyForm';
import {JsonCompany, JsonCompanySerieCompanySerieFlagsEnum, JsonCompanySerieInfo, JsonCompanyUserInfo, JsonUser, NotifyCompanySerieUsingPOSTNotificationTypeEnum} from '../../../generated-api';
import PageHeader from '../../../components/layout/PageHeader';
import {companyFactory, userFactory} from '../../../model/factories';
import {Button, Container, Grid} from '@mui/material';
import CompanyAdminModal from '../../../modals/CompanyAdminModal';
import {saveUser} from '../../../store/users';
import {addMessage} from '../../../store/localApp';
import CompanyAdministratorsList from './components/CompanyAdministratorsList';
import CompanySeriesList, {CompanySeriesListActions} from './components/CompanySeriesList';
import { useModal } from '../../../services/modal';
import { CompanySerieFormData } from '../../../forms/CompanySerieForm';
import CompanySerieModal from '../../../modals/CompanySerieModal';
import { useWithLoader } from '../../../services/withLoader';
import { fetchSerie } from '../../../store/series';
import CompanyPoplist, { CompanyPoplistTypeEnum } from './components/CompanyPoplist';
import ImportCompanySerieUsersModal, {ImportUsersParams} from "../../../modals/ImportCompanySerieUsersModal";

interface Props {
  createNew?: boolean;
}

const EditCompanyPage = ({ createNew }: Props) => {
	const t = useAppTranslation();
	const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const modal = useModal();
  const withLoader = useWithLoader();

  const [company, setCompany] = useState<JsonCompany | null>(null);
  const [companyUsers, setCompanyUsers] = useState<JsonCompanyUserInfo[] | null>(null);
  const [companySeries, setCompanySeries] = useState<JsonCompanySerieInfo[] | null>(null);
  const [newCompanyAdmin, setNewCompanyAdmin] = useState<JsonUser | null>(null);
  const [newCompanySerie, setNewCompanySerie] = useState<CompanySerieFormData | null>(null);
  const [importUsersParams, setImportUsersParams] = useState<ImportUsersParams | null>(null);

  const { id } = useParams();

  const loadCompanyUsers = useCallback(async (id: number) => {
    const result = await dispatch(fetchCompanyUsers({ companyId: +id, roleCodes: ['COMPANY_ADMIN'] }));
    if ('error' in result) {
      return;
    } else {
      setCompanyUsers(result.payload.data || null);
    }
  }, [dispatch]);

  const loadCompanySeries = useCallback(async (id: number) => {
    const result = await dispatch(fetchCompanySeries(+id));
    if ('error' in result) {
      return;
    } else {
      setCompanySeries(result.payload.data || null);
    }
  }, [dispatch]);

  useEffect(() => {
    if (createNew) {
      setCompany(companyFactory());
      return;
    } 
    if (id) {
      dispatch(fetchCompany(+id))
        .then((result) => {
          setCompany((result?.payload as any).data);
          return Promise.all([loadCompanyUsers(+id), loadCompanySeries(+id)]);
        })
        .then((result) => {
          // console.log({ result });
        });
    }
	}, [dispatch, createNew, id, loadCompanyUsers, loadCompanySeries]);

  const handleSave = useCallback(async (data: JsonCompany) => {
    console.log({ data });
    const result = await dispatch(saveCompany(data));
    if ('error' in result) {
      const errorMessage = result.error.message?.indexOf('uq_company_number') !== -1 ? t('Společnost s tímto IČ již existuje.') : t('Chyba při ukládání společnosti.');
      dispatch(addMessage({
        severity: 'error',
        title: errorMessage,
      }));
      return;
    }
    dispatch(addMessage({
      severity: 'success',
      title: t('Společnost byla uložena.'),
    }));
    navigate('/companies');
  }, [navigate, dispatch, t])

  // const handleCancel = useCallback(() => {
  //   console.log('cancel');
  //   navigate('/companies');
  // }, [navigate])
  //
  const handleAddCompanyAdmin = useCallback(() => {
    setNewCompanyAdmin(userFactory());
  }, []);


  const handleSaveCompanyAdmin = useCallback(async (user: JsonUser) => {
    if (company?.companyId) {
        const resultNewUser = await dispatch(saveUser({ ...user, roleCode: 'COMPANY_ADMIN' }));
        if ('error' in resultNewUser) {
          dispatch(addMessage({
            severity: 'error',
            title: resultNewUser.error.message === 'ENTITY_ALREADY_EXISTS' ?  t('Uživatel již existuje.') : t('Chyba při vytváření uživatele.'),
          }));
          return;
        }
        const resultCompanyUser = await dispatch(saveCompanyUser({ companyId: company.companyId as number, user: resultNewUser.payload.data as JsonUser, roleCode: 'COMPANY_ADMIN' }));
        if ('error' in resultCompanyUser) {
          dispatch(addMessage({
            severity: 'error',
            title: resultCompanyUser.error.message === 'ENTITY_ALREADY_EXISTS' ?  t('Uživatel je již ke společnosti přiřazen.') : t('Chyba při přiřazování uživatele ke společnosti.'),
          }));
          return;
        }
        dispatch(addMessage({
          severity: 'success',
          title: t('Administrátor společnosti byl vytvořen.'),
        }));
        loadCompanyUsers(company.companyId as number);
        setNewCompanyAdmin(null);
    }
  }, [dispatch, company, loadCompanyUsers, t])

  const handleCancelCompanyAdmin = useCallback(() => {
    setNewCompanyAdmin(null);
  }, [])

  const handleActionCompanyAdmin = useCallback(async (admin: JsonCompanyUserInfo, action: string) => {

    const result = await modal.confirm({ title: t('Potvrzení'), message: t('Skutečně chcete odstranit administrátora ze společnosti?') });
    if (result !== 'CONFIRM') {
      return;
    }

    await withLoader(async () => {
      if (company?.companyId && admin?.companyUserId) {
      const resultDeleteUser = await dispatch(deleteCompanyUser(admin.companyUserId));
      if ('error' in resultDeleteUser) {
        dispatch(addMessage({
          severity: 'error',
          title: t('Došlo k chybě při odstraňování administrátora.'),
        }));
        return;
      }
      await loadCompanyUsers(company.companyId);        
      dispatch(addMessage({
        severity: 'success',
        title: t('Administrátor by odstraněn.'),
      }));
      }
    });
  }, [dispatch, modal, company, loadCompanyUsers, withLoader, t]);

  /* serie */

  const handleAddSerie = useCallback(() => {
    setNewCompanySerie({ serieId: null });
  }, []);

  const handleSaveCompanySerie = useCallback(async (serie: CompanySerieFormData) => {
    if (company?.companyId && serie?.serieId) {
        const serieResult = await dispatch(fetchSerie(serie.serieId));
        console.log({ serieResult });
        if ('error' in serieResult) {
          dispatch(addMessage({
            severity: 'error',
            title: t('Chyba při načítání dat seriálu'),
          }));
          return;
        }
        const serieFlags: unknown = serieResult.payload.data?.serieFlags;
        const result = await dispatch(saveCompanySerie({ serieId: serie.serieId, companyId: company.companyId, companySerieFlags: serieFlags as JsonCompanySerieCompanySerieFlagsEnum[] }));
        if ('error' in result) {
          dispatch(addMessage({
            severity: 'error',
            title: result.error.message === 'ENTITY_ALREADY_EXISTS' ?  t('Seriál již je ke společnosti přiřazen.') : t('Chyba při přiřazování seriálu ke společnosti.'),
          }));
          return;
        }
        dispatch(addMessage({
          severity: 'success',
          title: t('Seriál byl přiřazen ke společnosti.'),
        }));
        loadCompanySeries(company.companyId);
        setNewCompanySerie(null);
    }
  }, [ dispatch, company, loadCompanySeries, t ])

  const handleCancelCompanySerie = useCallback(() => {
    setNewCompanySerie(null);
  }, [])

  const handleActionSerie = useCallback(async (serie: JsonCompanySerieInfo, action: string) => {

    if (company?.companyId && serie?.companySerieId) {
      switch (action) {
        case CompanySeriesListActions.ImportUsers:
          setImportUsersParams({ serieId: serie.serieId, companyId: company.companyId });
          break;
        case NotifyCompanySerieUsingPOSTNotificationTypeEnum.SerieAdminLink:
          const adminResult = await modal.confirm({ title: t('Potvrzení'), message: t('Skutečně chcete odeslat registrační link na všechny administrátory této společnosti?') });
          if (adminResult !== 'CONFIRM') {
            return;
          }
          await withLoader(async () => {
              const adminSendResult = await dispatch(sendAdminLink(serie?.companySerieId as number));
              if ('error' in adminSendResult) {
                dispatch(addMessage({
                  severity: 'error',
                  title: t('Došlo k chybě při odesílání registračního linku na administrátory.'),
                }));
                return;
              }
              dispatch(addMessage({
                severity: 'success',
                title: t('Registrační link byl odeslán na administrátory.'),
              }));
            });
          break;
        case NotifyCompanySerieUsingPOSTNotificationTypeEnum.SerieUserLink:
          const userResult = await modal.confirm({ title: t('Potvrzení'), message: t('Skutečně chcete odeslat přihlašovací link na všechny zaregistrované studenty?') });
          if (userResult !== 'CONFIRM') {
            return;
          }
          await withLoader(async () => {
            const userSendResult = await dispatch(sendUserLink(serie?.companySerieId as number));
            if ('error' in userSendResult) {
              dispatch(addMessage({
                severity: 'error',
                title: t('Došlo k chybě při odesílání přihlašovacího linku na studenty.'),
              }));
              return;
            }
            dispatch(addMessage({
              severity: 'success',
              title: t('Přihlašovací link na registrované studenty byl odeslán.'),
            }));
          });
          break;
      }
    }
  }, [dispatch, modal, company, withLoader, t]);

  return (
    <>
		{ !!company &&
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader title={company.companyName as string} showBack></PageHeader>
          </Grid>
          { !company.companyId && <Grid item xs={3}></Grid>}
          <Grid item xs={6}>
            <CompanyForm data={company} onSave={handleSave} title={t('Parametry společnosti')} cardLayout></CompanyForm>
          </Grid>
          { company.companyId &&
            <Grid item xs={6}>
              <Grid item xs={12} marginBottom={2}>
                <CompanySeriesList rows={companySeries} action={handleActionSerie}>
                  <Button variant="contained" onClick={handleAddSerie} fullWidth>{ t('Přidat seriál')}</Button>
                </CompanySeriesList>
              </Grid>
              <Grid item xs={12} marginBottom={2}>
                <CompanyAdministratorsList rows={companyUsers} action={handleActionCompanyAdmin}>
                  <Button variant="contained" onClick={handleAddCompanyAdmin} fullWidth>{ t('Přidat administrátora')}</Button>
                </CompanyAdministratorsList>
              </Grid>
              <Grid item xs={12} marginBottom={2}>
                <CompanyPoplist type={ CompanyPoplistTypeEnum.Departmnets } companyId={ company.companyId }></CompanyPoplist>
              </Grid>
              <Grid item xs={12} marginBottom={2}>
                <CompanyPoplist type={ CompanyPoplistTypeEnum.Positions } companyId={ company.companyId }></CompanyPoplist>
              </Grid>
            </Grid>
          }
        </Grid>
      <CompanyAdminModal data={newCompanyAdmin} onCancel={handleCancelCompanyAdmin} onSave={handleSaveCompanyAdmin}/>
      <CompanySerieModal data={newCompanySerie} onCancel={handleCancelCompanySerie} onSave={handleSaveCompanySerie}/>
      <ImportCompanySerieUsersModal data={importUsersParams} onClose={() => setImportUsersParams(null)} />
      </Container>
  }
  </>
	);
}

export default EditCompanyPage;
