import {RegistrationFormData} from "../forms/RegistrationForm"
import {
    JsonCompany,
    JsonEpisode,
    JsonGroup,
    JsonGroupStatusEnum,
    JsonPoll,
    JsonPollChartTypeEnum,
    JsonPollStatusEnum,
    JsonSerie,
    JsonStratCvb,
    JsonStratCvbStatusEnum,
    JsonStrategy,
    JsonStratEnabler,
    JsonStratEnablerStatusEnum,
    JsonStratInitiative,
    JsonStratInitiativeStatusEnum,
    JsonStratPillar,
    JsonStratPillarStatusEnum,
    JsonStratSuccessLook,
    JsonStratSuccessLookStatusEnum,
    JsonUser
} from "../generated-api/models"

export const companyFactory = (): JsonCompany => {
    return {
        companyName: '',
        companyNumber: '',
        countryCode: '',
        regionCode: '',
        sectorId: 0,
        sizeId: 0
    }
}

export const userFactory = (): JsonUser => {
    return {
        firstName: undefined,    
        lastName: undefined,
        sex: undefined,
        email: undefined,
        phone: undefined,
        roleCode: undefined,
    }
}

export const registrationFactory = (): RegistrationFormData => {
    return {
        firstName: '',    
        lastName: '',
        sex: undefined,
        email: '',
        positionId: 0,
        companyPositionId: 0,
        departmentId: 0,
        companyDepartmentId: 0,
        durationId: 0,
        companyBranchId: 0,
        newPassword: '',
        verifyNewPassword: '',
        isNewsletter: false,
        isGdpr: false
    }
}

export const serieFactory = (): JsonSerie => {
    return {
        title: undefined,    
    }
}

export const episodeFactory = (serie: JsonSerie): JsonEpisode => {
    return {
        episodeFrom: '2023-01-01T00:00:00',
        episodeTo: '2023-01-01T00:00:00',
        title: undefined,    
        serie,
    }
}

export const groupFactory = (episodeId: number): JsonGroup => {
    return {
        episodeId,
        status: JsonGroupStatusEnum.Active,
        title: '',
        orderNo: 0
    };
}

export const pollFactory = (episodeId: number, groupId?: number): JsonPoll => {
    return {
        episodeId,
        groupId,
        status: JsonPollStatusEnum.Active,
        title: '',
        orderNo: 0,
        chartType: JsonPollChartTypeEnum.None,
        pollType: undefined
    };
}

export const strategyFactory = (): JsonStrategy => {
    return {
        companyId: undefined,
        status: undefined,
        strategyId: undefined,
        title: undefined,
    }
}

export const stratCvbFactory = (onepagerId: number): JsonStratCvb => {
    return {
        onepagerId,
        description: '',
        status: JsonStratCvbStatusEnum.Active
    }
}

export const stratPillarFactory = (onepagerId: number): JsonStratPillar => {
    return {
        onepagerId,
        description: '',
        initiatives: [],
        status: JsonStratPillarStatusEnum.Active
    }
}

export const stratInitiativeFactory = (pillarId?: number): JsonStratInitiative => {
    return {
        pillarId,
        description: '',
        status: JsonStratInitiativeStatusEnum.Active
    }
}

export const stratSuccessLookFactory = (pillarId?: number): JsonStratSuccessLook => {
    return {
        pillarId,
        description: '',
        status: JsonStratSuccessLookStatusEnum.Active
    }
}
export const stratEnablerFactory = (onepagerId: number): JsonStratEnabler => {
    return {
        onepagerId,
        description: '',
        status: JsonStratEnablerStatusEnum.Active
    }
}
