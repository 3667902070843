import styles from '../../assets/styles/layout';
import * as React from "react";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

interface Props {
    currentStep: number;
    steps: number;
    onChange: (step: number) => void;
}

const MicroSitePagination = ({ currentStep, steps, onChange }: Props) => {

    const stepItems = Array.from({length: steps}, (_, i) => i + 1)

	return (
        <div className={styles.micrositePagination}>
            <div className={styles.content}>
                <div className={styles.steps}>
                    {(stepItems || []).map((step: number) => <div onClick={() => onChange(step)}
                                                                  className={step === currentStep ? styles.active : styles.inactive}></div>)}
                </div>
                <div className={styles.buttons}>
                    <div className={styles.button} onClick={() => onChange(currentStep > 2 ? currentStep - 1 : 1)}><ArrowBackIosNewIcon/></div>
                    <div className={styles.button} onClick={() => onChange(currentStep < steps ? currentStep + 1 : steps)}><ArrowForwardIosIcon/></div>
                </div>
            </div>
        </div>
    );
}

export default MicroSitePagination;
