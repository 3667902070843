import {
	createSlice,
	createAsyncThunk,
	PayloadAction,
} from '@reduxjs/toolkit'
import { RootState } from '..';
import {API_CONFIG} from '../app/api-config';
import {
	FileControllerApi,
	JsonCompanyDepartment,
	JsonCompanyDepartmentStatusEnum,
	JsonCompanyPosition,
	JsonCompanyPositionStatusEnum,
	JsonCompanySerieCompanySerieFlagsEnum,
	JsonUser, PrepareSerieUserImportUsingPOSTRequest, SaveAttachmentFileUsingPOSTRequest, SerieUserControllerApi
} from '../generated-api';
import {CompanyControllerApi, GetCompanyListUsingGETRequest, JsonCompany, JsonCompanyInfo} from '../generated-api';
import { CompanyDepartmentControllerApi, CompanyPositionControllerApi, CompanySerieControllerApi, CompanyUserControllerApi, GetCompanyCountUsingGETRequest, GetCompanyDepartmentListUsingGETStatusEnum, GetCompanyPositionListUsingGETStatusEnum, NotifyCompanySerieUsingPOSTNotificationTypeEnum } from '../generated-api/apis';

const companiesApi = new CompanyControllerApi(API_CONFIG);
const companyUsersApi = new CompanyUserControllerApi(API_CONFIG);
const companySeriesApi = new CompanySerieControllerApi(API_CONFIG);
const companyDepartmentsApi = new CompanyDepartmentControllerApi(API_CONFIG);
const companyPositionsApi = new CompanyPositionControllerApi(API_CONFIG);
const serieUsersApi = new SerieUserControllerApi(API_CONFIG);
const fileApi = new FileControllerApi(API_CONFIG);

export const fetchCompanies = createAsyncThunk('companies/list', async (arg: { page?: number, offset?: number }, thunkApi) => {
	
	const { currentPage, rowsPerPage, filter } = (thunkApi.getState() as RootState).companies; 

	console.log({ currentPage, rowsPerPage, filter });

	const params = {
		start: arg.page ? (arg.page - 1) * rowsPerPage : ((currentPage - 1) + (arg?.offset || 0)) * rowsPerPage,
		rows: rowsPerPage,
		orderCol: 'companyName',
		orderDir: 'asc',
		...filter
	} as GetCompanyListUsingGETRequest;
	
	return await companiesApi.getCompanyListUsingGET(params);
});

export const fetchCompaniesCount = createAsyncThunk('companies/count', async (_: void, thunkApi) => {

	const { filter } = (thunkApi.getState() as RootState).companies; 

	const params = {
		...filter,
	} as GetCompanyListUsingGETRequest;

	return await companiesApi.getCompanyCountUsingGET(params);
});

export const fetchCompany = createAsyncThunk('company/fetch', async (companyId: number) => {
	return await companiesApi.getCompanyUsingGET({ companyId });
});

export const saveCompany = createAsyncThunk('company/save', async (company: JsonCompany) => {
	// ['createdAt', 'createdBy'].forEach((field: string) => { delete company[field] });
	delete company['createdAt'];
	delete company['createdBy'];
	delete company['updatedAt'];
	delete company['updatedBy'];
	if (!company['regionCode']) {
		delete company['regionCode'];
	}
	return await companiesApi.saveCompanyUsingPOST({ json: company });
});

export const fetchCompanyUsers = createAsyncThunk('company/fetchUsers', async (props: { companyId: number, roleCodes: string[] }) => {
	return await companyUsersApi.getCompanyUserListUsingGET({ companyId: props.companyId, roleCodes: props.roleCodes });
});

export const saveCompanyUser = createAsyncThunk('company/saveUser', async (params: { companyId: number, user: JsonUser, roleCode: string }) => {
	return await companyUsersApi.saveCompanyUserUsingPOST({ json: { companyId: params.companyId, userId: params.user.userId, roleCode: params.roleCode }});
});

export const deleteCompanyUser = createAsyncThunk('company/deleteUser', async (companyUserId: number) => {
	return await companyUsersApi.deleteCompanyUserUsingDELETE({ companyUserId });
});

export const fetchCompanySeries = createAsyncThunk('company/fetchSeries', async (companyId: number) => {
	return await companySeriesApi.getCompanySerieListUsingGET({ companyId });
}); 

export const saveCompanySerie = createAsyncThunk('company/saveSerie', async (params: { companyId: number, serieId: number, companySerieFlags?: Array<JsonCompanySerieCompanySerieFlagsEnum> }) => {
	return await companySeriesApi.saveCompanySerieUsingPOST({ json: { companyId: params.companyId, serieId: params.serieId, companySerieFlags: params.companySerieFlags }});
});

export const sendAdminLink = createAsyncThunk('company/sendAdminLink', async (companySerieId: number) => {
	return await companySeriesApi.notifyCompanySerieUsingPOST({
		companySerieId,
		notificationType: NotifyCompanySerieUsingPOSTNotificationTypeEnum.SerieAdminLink
	 });
});

export const sendUserLink = createAsyncThunk('company/sendUserLink', async (companySerieId: number) => {
	return await companySeriesApi.notifyCompanySerieUsingPOST({
		companySerieId,
		notificationType: NotifyCompanySerieUsingPOSTNotificationTypeEnum.SerieUserLink
	 });
});

export const fetchCompanyDepartments = createAsyncThunk('company/fetchDepartments', async (companyId: number) => {
	return await companyDepartmentsApi.getCompanyDepartmentListUsingGET({ companyId, status: GetCompanyDepartmentListUsingGETStatusEnum.Active });
}); 

export const saveCompanyDepartment = createAsyncThunk('company/saveDepartment', async (department: JsonCompanyDepartment) => {
	return await companyDepartmentsApi.saveCompanyDepartmentUsingPOST({ json: department });
});

export const deleteCompanyDepartment = createAsyncThunk('company/deleteDepartment', async (department: JsonCompanyDepartment) => {
	return await companyDepartmentsApi.saveCompanyDepartmentUsingPOST({ json: { ...department, status: JsonCompanyDepartmentStatusEnum.Deleted }});
});

export const fetchCompanyPositions = createAsyncThunk('company/fetchPositions', async (companyId: number) => {
	return await companyPositionsApi.getCompanyPositionListUsingGET({ companyId, status: GetCompanyPositionListUsingGETStatusEnum.Active });
}); 

export const saveCompanyPosition = createAsyncThunk('company/savePosition', async (position: JsonCompanyPosition) => {
	return await companyPositionsApi.saveCompanyPositionUsingPOST({ json: position });
});

export const deleteCompanyPosition = createAsyncThunk('company/deletePosition', async (position: JsonCompanyPosition) => {
	return await companyPositionsApi.saveCompanyPositionUsingPOST({ json: { ...position, status: JsonCompanyPositionStatusEnum.Deleted }});
	// return await companyPositionsApi.deleteCompanyPositionUsingDELETE({ companyPositionId });
});

export const saveCompanyLogo = createAsyncThunk('company/saveLogo', async (request: SaveAttachmentFileUsingPOSTRequest) => {
	return fileApi.saveAttachmentFileUsingPOST(request);
});

export const importSerieUsers = createAsyncThunk('company/import-serie-users', async (request: PrepareSerieUserImportUsingPOSTRequest) => {
	return serieUsersApi.prepareSerieUserImportUsingPOST(request);
});


export type CompaniesState = {
	loading: boolean;
	rowsPerPage: number;
	currentPage: number;
	count: number;
	items: JsonCompanyInfo[];
	filter: GetCompanyCountUsingGETRequest;
}

const initialState: CompaniesState = {
	loading: false,
	rowsPerPage: 10,
	currentPage: 1,
	count: 0,
	items: [],
	filter: {}
};

export const companiesSlice = createSlice({
	name: 'companies',
	initialState,
	reducers: {
		setCompaniesFilter: (state, action: PayloadAction<GetCompanyCountUsingGETRequest>) => {
			state.filter = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(fetchCompanies.pending, (state, action) => ({ ...state, loading: true }));
		builder.addCase(fetchCompanies.fulfilled, (state, action) => {
			return { ...state, items: action.payload.data || [], currentPage: action.meta.arg.page || state.currentPage, loading: false };
		});
		builder.addCase(fetchCompanies.rejected, (state, action) => ({ ...state, items: [], loading: false }));

		builder.addCase(fetchCompaniesCount.pending, (state, action) => ({ ...state, loading: true }));
		builder.addCase(fetchCompaniesCount.fulfilled, (state, action) => ({ ...state, count: action.payload.data || 0, loading: false }));
		builder.addCase(fetchCompaniesCount.rejected, (state, action) => ({ ...state, count: 0, loading: false }));
	}
});

export const companiesReducer = companiesSlice.reducer;
export const { setCompaniesFilter } = companiesSlice.actions;
