import {TAppFunction, useAppTranslation} from "../../services/i18n";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {JsonGroup, JsonPoll, JsonPollPollTypeEnum, JsonPollResults, JsonPollStatusEnum, JsonRelayResponse} from "../../generated-api";
import {Button, Card, CardActions, CardContent, Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextareaAutosize, ToggleButton, ToggleButtonGroup, Tooltip} from "@mui/material";
import {getOptionTotal, TSendResponses} from "../../helpers/relay";
import styles from '../../assets/styles/relay';
import RankingQuestion from "./question/RankingQuestion";

const createResponse = (pollId: number, pollOptionId?: number, value?: string): JsonRelayResponse => {
    return {
        pollId,
        pollOptionId,
        value
    }
}

const renderChoice = (poll: JsonPoll, formReadOnly: boolean, newResponses: JsonRelayResponse[], setNewResponses: TSendResponses, results: JsonPollResults, showResults: boolean) => {
    const pollId = poll?.pollId as number;
    return <RadioGroup name="radio-buttons-group" className={styles.singleChoice}>
        {poll.options?.map((option, i) => {
            return <FormControlLabel onChange={() => setNewResponses([createResponse(pollId, option.optionId)])} key={i} value={option.optionId} control={<Radio
                disabled={formReadOnly}
                checked={!!newResponses?.find((r) => r.pollOptionId === option.optionId)}
            />} label={option.title + (showResults ? ' (' + getOptionTotal(option, results) + ')' : '')}/>;
        })}
    </RadioGroup>
}

const renderMultiChoice = (poll: JsonPoll, formReadOnly: boolean, newResponses: JsonRelayResponse[], setNewResponses: TSendResponses, results: JsonPollResults, showResults: boolean) => {
    const pollId = poll?.pollId as number;
    return <div className={styles.multiChoice}>
        {poll.options?.map((option, i) => {
            return <FormControlLabel onChange={() => {
                if (newResponses.find((r) => r.pollOptionId === option.optionId)) {
                    setNewResponses(newResponses.filter((r) => r.pollOptionId !== option.optionId));
                } else {
                    setNewResponses([...newResponses, createResponse(pollId, option.optionId)]);
                }
            }} key={i} value={option.optionId} control={<Checkbox
                disabled={formReadOnly}
                checked={!!newResponses.find((r) => r.pollOptionId === option.optionId)}
            />} label={option.title + (showResults ? ' (' + getOptionTotal(option, results) + ')' : '')}/>;
        })}
    </div>
}

const renderScale = (poll: JsonPoll, formReadOnly: boolean, newResponses: JsonRelayResponse[], setNewResponses: TSendResponses, results: JsonPollResults, showResults: boolean) => {
    const pollId = poll?.pollId as number;
    return <>
        <ToggleButtonGroup
            className={styles.scaleGroup}
            value={newResponses && newResponses[0] ? newResponses[0].pollOptionId : undefined}
            exclusive
            disabled={formReadOnly}
            onChange={(e, value) => {
                if (value) {
                    setNewResponses([createResponse(pollId, value)])
                } else {
                    setNewResponses([])
                }
            }}
        >
            {poll.options?.map((option, i) => {
                return <ToggleButton key={i} disabled={formReadOnly} value={option.optionId} className={styles.scaleOption}>{option.title}</ToggleButton>;
            })}
        </ToggleButtonGroup>
        {showResults && <div style={{display: "flex", justifyContent: "space-between"}}>{poll.options?.map((option, i) => {
            return <div key={i} style={{textAlign: "center", minWidth: "2em", fontSize: "15px"}}><small style={{color: "silver"}}>{getOptionTotal(option, results)}</small></div>
        })}</div>}
        {poll.pollParams && <div className={styles.scaleLabels}>
			<span>{poll.pollParams?.minLabel}</span>
			<span>{poll.pollParams?.maxLabel}</span>
		</div>}
    </>
}

const renderFreeText = (poll: JsonPoll, formReadOnly: boolean, newResponses: JsonRelayResponse[], setNewResponses: TSendResponses, responses: JsonRelayResponse[], t: TAppFunction) => {
    const pollId = poll?.pollId as number;
    return <div>
        {responses.map((r) => {
            if (r.value) {
                return <Tooltip key={r.responseId} title={<div style={{whiteSpace: "pre-wrap", fontSize: "115%"}}>{r.value}</div>}>
                    <p style={{margin: '8px 0', fontSize: '80%', background: 'rgba(239, 239, 239, 0.5)', padding: '8px '}}>
                    {r.value.length > 93 ? r.value.substring(0, 90) + '...' : r.value}
                    </p></Tooltip>;
            }
            return null;
        })}
        {responses.length > 0 && <p><em>{t('MicroSiteMainPage.messages.thanksNext')}</em></p>}
        <FormControlLabel
            className={styles.textarea}
            onChange={(e) => {
                const t = (e as React.ChangeEvent<HTMLInputElement>)?.target?.value as string;
                if (t?.trim()) {
                    setNewResponses([createResponse(pollId, undefined, t)]);
                } else {
                    setNewResponses([]);
                }
            }}
            value={newResponses && newResponses[0] ? newResponses[0].value : ''} control={<TextareaAutosize
            minRows={3}
            disabled={formReadOnly}
            maxLength={1000}
        />} label={null}/>
    </div>
}

const RelayItemPollQuestion = (props: { group?: JsonGroup, poll: JsonPoll, results: JsonPollResults, responses: JsonRelayResponse[], handleSendResponses?: TSendResponses }) => {
    const {group, poll, results, responses, handleSendResponses} = props;

    const allowMultipleResponses = useMemo(() => !group?.groupId
            && poll.pollType === JsonPollPollTypeEnum.Text
            && !!poll.pollParams?.allowMultipleResponses, // allow multiple text answers (if not in group)
        [group?.groupId, poll]);

    const t = useAppTranslation();
    const [newResponses, setNewResponses] = useState(allowMultipleResponses ? [] : responses); // populate by current (for this poll)
    const [isAnswering, setIsAnswering] = useState(false);
    const hasNewResponse = newResponses?.length > 0;

    const alreadyResponded = responses?.length > 0 && !allowMultipleResponses;
    const isDisabled = !handleSendResponses || alreadyResponded || poll.status !== JsonPollStatusEnum.Active;
    const isDuplicatedResponse = allowMultipleResponses && newResponses[0]
        && !!responses.filter(r => r.value?.trim() === newResponses[0].value?.trim()).length;
    const showResults = false; // !handleSendResponses || (alreadyResponded && !!results?.optionTotals);

    useEffect(() => {
        if (responses.length > 0) {
            setIsAnswering(false);
            if (allowMultipleResponses) {
                setNewResponses([]);
            }
        }
    }, [responses.length, allowMultipleResponses]);

    const setNewResponsesHandler: TSendResponses = useCallback((response) => {
        if (group?.groupId && handleSendResponses) {
            handleSendResponses(response);
        }
        setNewResponses(response);
    }, [group?.groupId, handleSendResponses]);

    const pollId = poll.pollId as number;
    const formReadOnly = isDisabled || isAnswering;
    let controls;
    switch (poll.pollType) {
        case JsonPollPollTypeEnum.Choice:
            controls = renderChoice(poll, formReadOnly, newResponses, setNewResponsesHandler, results, showResults);
            break;
        case JsonPollPollTypeEnum.Multichoice:
            controls = renderMultiChoice(poll, formReadOnly, newResponses, setNewResponsesHandler, results, showResults);
            break;
        case JsonPollPollTypeEnum.Scale:
            controls = renderScale(poll, formReadOnly, newResponses, setNewResponsesHandler, results, showResults);
            break;
        case JsonPollPollTypeEnum.Text:
            controls = renderFreeText(poll, formReadOnly, newResponses, setNewResponsesHandler, allowMultipleResponses ? responses : [], t);
            break;
        default:
            controls = null;
            break;
    }

    const k = 'item-poll-question-' + pollId;
    const form = <FormControl fullWidth>
        <FormLabel id={k + '-title'} className={styles.formLabel}><strong>{poll.title}</strong></FormLabel>
        {controls && <RadioGroup aria-labelledby={k + '-title'} name="radio-buttons-group">
            {controls}
		</RadioGroup>}
        {poll.pollType === JsonPollPollTypeEnum.Ranking &&
			<RankingQuestion poll={poll} formReadOnly={formReadOnly} newResponses={newResponses} setNewResponses={setNewResponsesHandler} results={results} showResults={showResults}/>
        }
    </FormControl>;

    return <Card key={k} variant="elevation" className={styles.relayItemCard}>
        <CardContent>
            {form}
            {alreadyResponded && !group && <div style={{paddingTop: "10px"}}><em>{t('MicroSiteMainPage.messages.thanks')}</em></div>}
        </CardContent>
        {!isDisabled && !group && <CardActions className={styles.relayItemActions}>
			<Button variant="contained" disabled={!hasNewResponse || isAnswering || isDuplicatedResponse} onClick={() => {
                if (newResponses) {
                    handleSendResponses(newResponses);
                    setIsAnswering(true);
                }
            }}>{isAnswering ? t('buttons.sending') : (allowMultipleResponses && responses.length > 0 ? t('buttons.sendNext') : t('buttons.send'))}</Button>
            { /* JSON.stringify(newResponses, null, 2) */}
		</CardActions>}
    </Card>
};

export default RelayItemPollQuestion;
