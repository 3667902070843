import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {Alert, Button, LinearProgress} from '@mui/material';
import {useSearchParams} from "react-router-dom";
import {getLocale, TAppFunction, useAppTranslation} from '../../services/i18n';
import {apiBasePath} from "../../app/config";
import SockJS from 'sockjs-client';
import {
    JsonGroup,
    JsonPoll,
    JsonPollChartTypeEnum,
    JsonPollResults,
    JsonRelayItem,
    JsonRelayItemItemTypeEnum,
    JsonRelayItemStatusEnum,
    JsonRelayResponse,
    JsonSerieSerieTypeEnum,
    JsonWebSocketMessage,
    JsonWebSocketMessageTypeEnum
} from "../../generated-api";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import {Client} from "stompjs";
import RelayItemPollQuestion from "../../components/relay/RelayItemPollQuestion";
import RelayItemPollResults from "../../components/relay/RelayItemPollResults";
import RelayItemGroup from "../../components/relay/RelayItemGroup";
import RelayStats from "../../components/relay/RelayStats";
import {ADMIN_CLIENT_GUID, checkGroupResponse, TSendResponses} from "../../helpers/relay";
import styles from '../../assets/styles/relay';
import RelayItemPollFullScreenResults from "../../components/relay/RelayItemPollFullScreenResults";
import {useTranslation} from "react-i18next";
import AuditPageStepper from "../public/auditPage/AuditPageStepper";
import MicroSitePagination from "./MicroSitePagination";
import atairuLabLogo from '../../assets/images/lab/atairulab_logo_dark.svg';

const {Stomp} = require('stompjs/lib/stomp.js');

interface RelayState {
    stompClient?: Client,
    isLoading: boolean,
    isConnected: boolean,
    items: { [key: number]: JsonRelayItem }, // by itemId
    pollResponses: { [key: number]: JsonRelayResponse[] }, // by pollId
    pollResults: { [key: number]: JsonPollResults }, // by pollId & optionId
    relayStats: any,
    errors: string[]
}

const defaultRelayState: RelayState = {
    stompClient: undefined,
    isLoading: true,
    isConnected: false,
    items: {},
    pollResponses: {},
    pollResults: {},
    relayStats: undefined,
    errors: []
}

export const renderItem = (item: JsonRelayItem, results: JsonPollResults, responses: JsonRelayResponse[], t: TAppFunction, handleSendResponses?: TSendResponses, group?: JsonGroup, groupIndex?: number): ReactJSXElement => {
    switch (item.itemType) {
        case JsonRelayItemItemTypeEnum.PollQuestion:
        case JsonRelayItemItemTypeEnum.PollResults:
        default:
            const poll = item.poll as JsonPoll;
            if (poll?.pollId) {
                switch (item.itemType) {
                    case JsonRelayItemItemTypeEnum.PollQuestion:
                        return <RelayItemPollQuestion
                            key={item.itemId}
                            group={group}
                            poll={poll}
                            results={results}
                            responses={responses}
                            handleSendResponses={handleSendResponses}/>

                    case JsonRelayItemItemTypeEnum.PollResults:
                        return <RelayItemPollResults
                            key={item.itemId}
                            group={group}
                            groupIndex={groupIndex}
                            poll={poll}
                            results={results}
                            responses={responses}/>;
                }
            }
            return <div key={item.itemId}>{JSON.stringify(item)}</div>;
    }
}

const isPollHidden = (pollId: number, item: JsonRelayItem, items: JsonRelayItem[], responses: JsonRelayResponse[]) => {
    const other = items.filter((item2) => item2.poll?.pollId === pollId && item2.itemId !== item.itemId && item2.status === JsonRelayItemStatusEnum.Active)[0];
    // show results only if answered already and vice versa - if streaming both question & answers
    switch (item.itemType) {
        case JsonRelayItemItemTypeEnum.PollQuestion:
            if (other && other.itemType === JsonRelayItemItemTypeEnum.PollResults && other.poll?.chartType !== JsonPollChartTypeEnum.None) {
                return responses != null && responses.length > 0; // hide question if answered already
            }
            break;
        case JsonRelayItemItemTypeEnum.PollResults:
            if (other && other.itemType === JsonRelayItemItemTypeEnum.PollQuestion) {
                return responses == null || responses.length <= 0; // hide results if not answered yet
            }
            break;
        default:
    }
    return false;
}

const sortItems = (a: JsonRelayItem, b: JsonRelayItem): number => {
    if ((a.poll?.pollId || 0) === (b.poll?.pollId || 0)) {
        return a.itemType === JsonRelayItemItemTypeEnum.PollResults ? 1 : -1;
    }
    return (a.poll?.orderNo || 0) > (b.poll?.orderNo || 0) ? 1 : -1;
}

export const renderFullScreenResultItem = (item: JsonRelayItem, results: JsonPollResults, responses: JsonRelayResponse[], group?: JsonGroup, groupIndex?: number): ReactJSXElement => {
        const poll = item.poll as JsonPoll;
        return (
            <div className={styles.fullScreenItem}>
                <div className={styles.title}>{ poll.title }</div>
                <div className={styles.container}>
                <RelayItemPollFullScreenResults
                    key={item.itemId}
                    group={group}
                    groupIndex={groupIndex}
                    poll={poll}
                    results={results}
                    responses={responses}
                    groupCount={1}/>
                </div>
            </div>
        );
}

export const renderFullScreenResultGroup = (item: JsonRelayItem[], results: { [key: number]: JsonPollResults }, responses: {  [key: number]: JsonRelayResponse[] }, group?: JsonGroup, groupIndex?: number): ReactJSXElement => {
    const length = item?.length || 0;
    return (
        <div className={styles.fullScreenItem}>
            <div className={styles.title}>{ group?.title }</div>
            <div className={styles.container}>
                <div className={styles.column}>
                { item.map((item: JsonRelayItem, index: number) => {
                    const poll = item.poll as JsonPoll;
                    return length < 4 || index <= length / 2 ? <RelayItemPollFullScreenResults
                        key={item.itemId}
                        group={group}
                        groupIndex={groupIndex}
                        poll={poll}
                        results={results[poll.pollId!]}
                        responses={responses[poll.pollId!]}
                        groupCount={length}/> : <></>
                }) }
                </div>
                { (length > 3) && <div className={styles.column}>
                    { item.map((item: JsonRelayItem, index: number) => {
                        const poll = item.poll as JsonPoll;
                        return index > length / 2 && <RelayItemPollFullScreenResults
                            key={item.itemId}
                            group={group}
                            groupIndex={groupIndex}
                            poll={poll}
                            results={results[poll.pollId!]}
                            responses={responses[poll.pollId!]}
                            groupCount={length}/>
                    }) }
                </div>
                }
            </div>
        </div>
        )
;
}

const MicroSiteMainPage = (props: { lang?: string, relayGuid?: string, clientGuid?: string, serieType?: JsonSerieSerieTypeEnum, single?: boolean }) => {
    const t = useAppTranslation();
    const { i18n } = useTranslation();
    const [search] = useSearchParams();
    const [relayState, setRelayState] = useState(defaultRelayState);
    const [isSynced, setIsSynced] = useState(false);
    const [displayedItem, setDisplayedItem] = useState<number|undefined>(undefined);

    const relayGuid = search.get("relayGuid") || props.relayGuid || '1';
    const clientGuid = search.get("clientGuid") || props.clientGuid || 'AtairuTV';
    const lang = search.get("lang") || props.lang || 'cs';
    const single = search.get("signle") || props.single || false;

    const serieType = search.get("serieType") || props.serieType ||  JsonSerieSerieTypeEnum.Broadcast;

    const isFullscreen = clientGuid === 'results';

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [i18n, lang])

    const handleIncomingMessage = useCallback((payload: any/*Frame | string*/) => {
        // if (!(payload instanceof Stomp.Frame)) {
        //     console.log("Invalid payload: " + payload);
        // }
        setRelayState(state => ({...state, isLoading: false}));
        if (!payload.body) {
            setRelayState(state => ({...state, errors: [payload, ...state.errors.slice(0, 2)]}));
            return;
        }

        const message = JSON.parse(payload.body) as JsonWebSocketMessage;
        const messages = message.type === JsonWebSocketMessageTypeEnum.Bundle ? message.data as JsonWebSocketMessage[] : [message];
        const isSync = messages[0].type === JsonWebSocketMessageTypeEnum.RelaySync;
        if (isSync) {
            setRelayState(state => ({
                ...state,
                items: {},
                errors: []
            }));
            setIsSynced(true);
        }

        messages.forEach(message => {
            switch (message.type) {
                case JsonWebSocketMessageTypeEnum.RelaySync:
                    // no-op
                    break;
                case JsonWebSocketMessageTypeEnum.RelayItem:
                    const item = message.data as JsonRelayItem;
                    setRelayState(state => ({
                        ...state,
                        items: {
                            ...state.items,
                            [item.itemId as number]: item
                        },
                        pollResults: {
                            ...state.pollResults,
                            ...(item.pollResults ? {[item.pollResults.pollId as number]: item.pollResults} : {})
                        }
                    }));
                    break;

                case JsonWebSocketMessageTypeEnum.RelayResponse:
                    const response = message.data as JsonRelayResponse;
                    setRelayState(state => ({
                        ...state,
                        pollResponses: {
                            ...state.pollResponses,
                            [response.pollId as number]: [...(state.pollResponses[response.pollId as number] || []).filter(r => r.responseId !== response.responseId), response]
                        }
                    }));
                    break;

                case JsonWebSocketMessageTypeEnum.RelayResults:
                    const pollResults = message.data as JsonPollResults;
                    setRelayState(state => ({
                        ...state,
                        pollResults: {
                            ...state.pollResults,
                            [pollResults.pollId as number]: pollResults
                        }
                    }));
                    break;

                case JsonWebSocketMessageTypeEnum.RelayStats:
                    setRelayState(state => ({
                        ...state,
                        relayStats: message.data
                    }));
                    break;

                default:
                case JsonWebSocketMessageTypeEnum.Error:
                    let error = JSON.stringify(message.data); // JsonErrorInfo
                    if ((message?.data as any)['errorCode'] === 'INVALID_PARAMETER') {
                        if ((message?.data as any)['values'] && (message?.data as any)['values']['parameterName'] === 'clientGuid') {
                            error = t('MicroSiteMainPage.messages.invalidUserCode')
                        }
                        if ((message?.data as any)['values'] && (message?.data as any)['values']['parameterName'] === 'relayGuid') {
                            error = t('MicroSiteMainPage.messages.invalidRelayCode')
                            if ((message?.data as any)['values']['error'] === 'VALIDATION_ERROR') {
                                if ((message?.data as any)['values']['description'] === 'FINISHED') {
                                    error = t('MicroSiteMainPage.messages.relayEnded')
                                } else if ((message?.data as any)['values']['description'] === 'DRAFT') {
                                    error = t('MicroSiteMainPage.messages.relayNotStarted')
                                }
                            }
                        }
                    }

                    setRelayState(state => ({
                        ...state,
                        errors: [error, ...state.errors.slice(0, 2)]
                    }));
                    break;
            }
        });
    }, [t]);

    // initial connect & subscribe
    useEffect(() => {
        let stompClient: Client;

        let retryTimeout: any, syncTimeout: any, cleanupInterval: any, cleanupCount: 0;
        const connect = () => {
            setRelayState(state => ({...state, isLoading: true}));

            stompClient = Stomp.over(new SockJS(apiBasePath(getLocale()) + '/ws-service'));
            stompClient.debug = () => {
            };
            stompClient.connect({clientGuid}, () => {
                stompClient.subscribe('/topic/relay/' + relayGuid, handleIncomingMessage, {clientGuid});
                stompClient.subscribe('/user/relay/' + relayGuid, handleIncomingMessage, {clientGuid});

                if (ADMIN_CLIENT_GUID === clientGuid) {
                    stompClient.subscribe('/topic/relay/' + relayGuid + "/stats", handleIncomingMessage, {clientGuid});
                }

                syncTimeout = setTimeout(() => {
                    if (stompClient?.connected) {
                        setRelayState(state => ({...state, isLoading: true}));
                        stompClient?.send('/app/relay/' + relayGuid + '/sync', {clientGuid});
                    }
                }, 1000);

                setRelayState(state => ({...state, errors: [], stompClient: stompClient, isConnecting: false, isConnected: true}));

            }, () => {
                setRelayState(state => ({...state, isLoading: false, isConnected: false}));
                retryTimeout = setTimeout(connect, 10000);
            });
        }
        retryTimeout = setTimeout(connect, 2000);

        return (() => {
            if (syncTimeout) {
                clearTimeout(syncTimeout);
            }
            if (retryTimeout) {
                clearTimeout(retryTimeout);
            }
            cleanupInterval = setInterval(() => {
                if (stompClient?.connected) {
                    stompClient?.disconnect(console.log);
                    clearInterval(cleanupInterval);
                } else if (cleanupCount++ > 30) {
                    clearInterval(cleanupInterval);
                }
            }, 1000);
        })
    }, [relayGuid, clientGuid, handleIncomingMessage]);

    // make sure we keep pinging "sync" until we get it
    useEffect(() => {
        if (isSynced || !relayState.stompClient?.connected) {
            return;
        }
        let syncInterval = setInterval(() => {
            if (isSynced) {
                clearInterval(syncInterval);
                return;
            }
            if (relayState.stompClient?.connected) {
                setRelayState(state => ({...state, isLoading: true}));
                relayState.stompClient?.send('/app/relay/' + relayGuid + '/sync', {clientGuid});
            }

        }, 15000);

        return (() => {
            if (syncInterval) {
                clearInterval(syncInterval);
            }
        });

    }, [relayGuid, clientGuid, isSynced, relayState.stompClient]);

    const items: JsonRelayItem[] = [];
    Object.keys(relayState.items).forEach((itemId) => {
        items.push(relayState.items[parseInt(itemId)]);
    });

    const doneGroups: number[] = [];
    const activeItems: ReactJSXElement[] = [];
    const itemStatuses: boolean[] = [];

    if (isFullscreen) {
        items.forEach((item) => {
            const group = item.group;
            if (group?.groupId) {
                const groupKey = group.groupId;
                if (doneGroups.indexOf(groupKey) >= 0) {
                    return;
                }
                const groupItems = items.filter((item2) => item2.group?.groupId === group.groupId && item2.itemType === JsonRelayItemItemTypeEnum.PollResults);
                const groupPollResponses: { [key: number]: JsonRelayResponse[] } = {}; // by pollId
                const groupPollResults: { [key: number]: JsonPollResults } = {}; // by pollId & optionId
                groupItems.forEach((item) => {
                    const pollId = item.poll?.pollId;
                    if (pollId) {
                        groupPollResponses[pollId] = relayState.pollResponses[pollId] || [];
                        groupPollResults[pollId] = relayState.pollResults[pollId];
                    }
                });
                if (groupItems.length) {
                    activeItems.push(renderFullScreenResultGroup(groupItems, groupPollResults, groupPollResponses, group));
                }
                doneGroups.push(groupKey);
                // TODO
            } else {
                const pollId = item.poll?.pollId;
                const responses = (pollId && relayState.pollResponses[pollId]) || [];
                const results = (pollId && relayState.pollResults[pollId]) || {};

                if (item.itemType === JsonRelayItemItemTypeEnum.PollResults) {
                    activeItems.push(renderFullScreenResultItem(item, results, responses));
                }
            }
        });
        if (!displayedItem && activeItems.length) {
            setDisplayedItem(1);
        }
        if (displayedItem && displayedItem > activeItems.length) {
            setDisplayedItem(activeItems.length);
        }
    } else {
        items.forEach((item) => {
            if (item.status !== JsonRelayItemStatusEnum.Active) {
                return;
            }
            const handleSendResponses = ADMIN_CLIENT_GUID === clientGuid
                ? undefined
                : ((newResponses) => {
                    if (relayState.stompClient?.connected) {
                        setRelayState(state => ({...state, isLoading: true}));
                        relayState.stompClient.send('/app/relay/' + relayGuid + '/responses', {clientGuid}, JSON.stringify(newResponses));
                    }
                }) as TSendResponses;

            const group = item.group;
            if (group?.groupId) {
                const groupKey = group.groupId;
                if (doneGroups.indexOf(groupKey) >= 0) {
                    return;
                }
                const groupItems = items.filter((item2) => item2.group?.groupId === group.groupId);
                const groupPollResponses: { [key: number]: JsonRelayResponse[] } = {}; // by pollId
                const groupPollResults: { [key: number]: JsonPollResults } = {}; // by pollId & optionId

                const hiddenItems: JsonRelayItem[] = [];
                groupItems.forEach((item) => {
                    const pollId = item.poll?.pollId;
                    if (pollId) {
                        groupPollResponses[pollId] = relayState.pollResponses[pollId] || [];
                        groupPollResults[pollId] = relayState.pollResults[pollId];

                        if (handleSendResponses && isPollHidden(pollId, item, items, groupPollResponses[pollId])) {
                            hiddenItems.push(item);
                        }
                    }
                });

                const groupItemsSorted = groupItems
                    .filter((i) => !(hiddenItems.indexOf(i) >= 0))
                    .sort(sortItems);

                activeItems.push(<RelayItemGroup
                    key={'group-' + groupKey}
                    group={group}
                    items={groupItemsSorted}
                    results={groupPollResults}
                    responses={groupPollResponses}
                    handleSendResponses={handleSendResponses}
                />);

                doneGroups.push(groupKey);
                itemStatuses.push(checkGroupResponse(groupItemsSorted, groupPollResponses));

            } else {

                const pollId = item.poll?.pollId;
                const responses = (pollId && relayState.pollResponses[pollId]) || [];
                const results = (pollId && relayState.pollResults[pollId]) || {};

                if (pollId && handleSendResponses && isPollHidden(pollId, item, items, responses)) {
                    return;
                }

                activeItems.push(renderItem(item, results, responses, t, handleSendResponses));
                itemStatuses.push(!!responses?.length);
            }
        });
        if (single) {
            if (!displayedItem && activeItems.length) {
                setDisplayedItem(1);
            }
            if (displayedItem && displayedItem > activeItems.length) {
                setDisplayedItem(activeItems.length);
            }
        }
    }

    let errors: JSX.Element[] = [];
    if (relayState.errors) {
        errors = relayState.errors.filter((v, i, a) => a.indexOf(v) === i)
            .map((error, i) =>
                <Alert key={i} severity="error" className={styles.relayAlert}>{error}</Alert>)
        if (errors.length > 0) {
            errors.push(<div key={errors.length}>
                <Button variant={'contained'} color={'error'} fullWidth onClick={() => window.location.reload()} className={styles.relayAlert}>{ t('MicroSiteMainPage.messages.reloadPage') }</Button>
            </div>);
        }
    }

    // https://mui.com/material-ui/getting-started/usage/
    return (
        <>
            { !isFullscreen &&
                <div style={{
                    maxWidth: serieType === JsonSerieSerieTypeEnum.Broadcast ? "330px" : "800px",
                    width: '100%',
                    margin: "0 auto",
                    padding: "10px",
                    minHeight: "calc(100vh - 130px)",
                    paddingTop: '120px',
                    zIndex: '10'
                }}>
                    {relayState.isLoading
                        ? <LinearProgress variant={'query'}/>
                        : (relayState.isConnected ? null : <Alert severity="warning"
                                                                  className={styles.relayAlert}>{t('MicroSiteMainPage.messages.connectionMissing')}</Alert>)}
                    {errors}
                    {serieType === JsonSerieSerieTypeEnum.Audit && <AuditPageStepper steps={itemStatuses}/>}
                    {relayState.relayStats && <RelayStats relayStats={relayState.relayStats}/>}
                    {!relayState.isLoading && !activeItems.length && <div className={styles.relayInfo}>{t('MicroSiteMainPage.messages.votingHere')}</div>}
                    {!single && !relayState.isLoading && activeItems }
                    {!!single && !relayState.isLoading &&
                        <>
                            {activeItems?.map((item, index) => (index + 1 === displayedItem) ? item : <></>)}
                            <MicroSitePagination
                                steps={activeItems.length}
                                currentStep={displayedItem!}
                                onChange={(page: number) => setDisplayedItem(page)}
                            />
                        </>
                    }
                </div>
            }
            {isFullscreen &&
                <div className={styles.fullScreenContainer}>
                    <div className={styles.logoContainer}>
                        <img className={styles.logo} alt="Atairu" src={atairuLabLogo}/>
                    </div>
                    {relayState.isLoading
                        ? <LinearProgress variant={'query'}/>
                        : (relayState.isConnected ? null : <Alert severity="warning"
                                                                  className={styles.relayAlert}>{t('MicroSiteMainPage.messages.connectionMissing')}</Alert>)}
                    {errors}
                    {activeItems?.map((item, index) => (index + 1 === displayedItem) ? item : <></>)}
                    {!relayState.isLoading && <MicroSitePagination
                        steps={activeItems.length}
                        currentStep={displayedItem!}
                        onChange={(page: number) => setDisplayedItem(page)}
                    />}
                </div>
            }
        </>
    );
}

export default MicroSiteMainPage;
