import {createTheme} from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: '#210055',
        },
        secondary: {
            main: '#D4AFEE',
        },
        success: {
            main: '#a6f5b4'
        },
        action: {
            disabledBackground: '#f5f5f5',
            disabled: '#888888'
        }
    },
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: '15px'
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 20,
                }
            }
        },
    }
});

export default theme;
