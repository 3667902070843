/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonCompanySerieInfo
 */
export interface JsonCompanySerieInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonCompanySerieInfo
     */
    allowedActions?: Array<JsonCompanySerieInfoAllowedActionsEnum>;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanySerieInfo
     */
    companyId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonCompanySerieInfo
     */
    companySerieFlags?: Array<JsonCompanySerieInfoCompanySerieFlagsEnum>;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanySerieInfo
     */
    companySerieId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanySerieInfo
     */
    companySerieUserCount?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanySerieInfo
     */
    serieGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanySerieInfo
     */
    serieId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanySerieInfo
     */
    serieStatus?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanySerieInfo
     */
    serieTitle?: string;
}

/**
* @export
* @enum {string}
*/
export enum JsonCompanySerieInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonCompanySerieInfoCompanySerieFlagsEnum {
    RegEmail = 'REG_EMAIL',
    RegPosition = 'REG_POSITION',
    RegDepartment = 'REG_DEPARTMENT',
    RegName = 'REG_NAME',
    RegBranch = 'REG_BRANCH',
    RegDuration = 'REG_DURATION',
    PollStepper = 'POLL_STEPPER'
}

export function JsonCompanySerieInfoFromJSON(json: any): JsonCompanySerieInfo {
    return JsonCompanySerieInfoFromJSONTyped(json, false);
}

export function JsonCompanySerieInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonCompanySerieInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'companySerieFlags': !exists(json, 'companySerieFlags') ? undefined : json['companySerieFlags'],
        'companySerieId': !exists(json, 'companySerieId') ? undefined : json['companySerieId'],
        'companySerieUserCount': !exists(json, 'companySerieUserCount') ? undefined : json['companySerieUserCount'],
        'serieGuid': !exists(json, 'serieGuid') ? undefined : json['serieGuid'],
        'serieId': !exists(json, 'serieId') ? undefined : json['serieId'],
        'serieStatus': !exists(json, 'serieStatus') ? undefined : json['serieStatus'],
        'serieTitle': !exists(json, 'serieTitle') ? undefined : json['serieTitle'],
    };
}

export function JsonCompanySerieInfoToJSON(value?: JsonCompanySerieInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'companyId': value.companyId,
        'companySerieFlags': value.companySerieFlags,
        'companySerieId': value.companySerieId,
        'companySerieUserCount': value.companySerieUserCount,
        'serieGuid': value.serieGuid,
        'serieId': value.serieId,
        'serieStatus': value.serieStatus,
        'serieTitle': value.serieTitle,
    };
}

