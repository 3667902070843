/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonRelayInfo
 */
export interface JsonRelayInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonRelayInfo
     */
    allowedActions?: Array<JsonRelayInfoAllowedActionsEnum>;
    /**
     * 
     * @type {number}
     * @memberof JsonRelayInfo
     */
    episodeId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonRelayInfo
     */
    finishedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonRelayInfo
     */
    finishedBy?: number;
    /**
     * 
     * @type {boolean}
     * @memberof JsonRelayInfo
     */
    isPollStepper?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JsonRelayInfo
     */
    lang?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonRelayInfo
     */
    relayGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonRelayInfo
     */
    relayId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonRelayInfo
     */
    startedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonRelayInfo
     */
    startedBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonRelayInfo
     */
    status?: JsonRelayInfoStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum JsonRelayInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonRelayInfoStatusEnum {
    Draft = 'DRAFT',
    Active = 'ACTIVE',
    Finished = 'FINISHED'
}

export function JsonRelayInfoFromJSON(json: any): JsonRelayInfo {
    return JsonRelayInfoFromJSONTyped(json, false);
}

export function JsonRelayInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonRelayInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'episodeId': !exists(json, 'episodeId') ? undefined : json['episodeId'],
        'finishedAt': !exists(json, 'finishedAt') ? undefined : json['finishedAt'],
        'finishedBy': !exists(json, 'finishedBy') ? undefined : json['finishedBy'],
        'isPollStepper': !exists(json, 'isPollStepper') ? undefined : json['isPollStepper'],
        'lang': !exists(json, 'lang') ? undefined : json['lang'],
        'relayGuid': !exists(json, 'relayGuid') ? undefined : json['relayGuid'],
        'relayId': !exists(json, 'relayId') ? undefined : json['relayId'],
        'startedAt': !exists(json, 'startedAt') ? undefined : json['startedAt'],
        'startedBy': !exists(json, 'startedBy') ? undefined : json['startedBy'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function JsonRelayInfoToJSON(value?: JsonRelayInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'episodeId': value.episodeId,
        'finishedAt': value.finishedAt,
        'finishedBy': value.finishedBy,
        'isPollStepper': value.isPollStepper,
        'lang': value.lang,
        'relayGuid': value.relayGuid,
        'relayId': value.relayId,
        'startedAt': value.startedAt,
        'startedBy': value.startedBy,
        'status': value.status,
    };
}

